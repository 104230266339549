



















import { Vue, Prop, Component } from 'vue-property-decorator';
import InsProductItem from '@/components/business/pc/product/InsProductItem.vue';
import { Promotion, Product } from '@/model/promotionResult';
@Component({ components: { InsProductItem } })
export default class EdProductPromotion extends Vue {
  private promotion: Promotion = new Promotion();
  @Prop({ default: 4 }) column!: number;
  @Prop({ default: 100 }) w!: number;
  @Prop({ default: true }) border!: boolean;
  @Prop({ default: true }) top!: boolean;
  @Prop({ default: true }) button!: boolean;
  @Prop() page!:string;
  @Prop() position!:string;
  created () {
    if (!this.page) throw new Error('推廣頁面不可爲空');
    if (!this.position) throw new Error('推廣位置不可爲空');
    this.$Api.promotion.getPromotion(this.page, this.position).then(result => {
      this.promotion = result.Promotion;
      if (this.promotion.PrmtProductList.length % this.column !== 0) {
        let count =
          this.column - (this.promotion.PrmtProductList.length % this.column);
        for (let i = 0; i < count; i++) {
          this.promotion.PrmtProductList.push(new Product(true));
        }
      }
    });
  }
}
